.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
}
body{
  background: linear-gradient(30deg,#EFEFEF, white);
  min-height: 100vh;
}
h1,h2,h3,span{
  font-family: 'Nunito', sans-serif;
}

*{
  padding: 0;
  margin: 0;
}

a{
  text-decoration: none;
  color: white;
}

#Header img{
  width: 100px;
}

#Header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 60px);
  padding: 10px 30px;
  border-bottom:1px solid gray;
  background: linear-gradient(-180deg, #34495E,#1B2631);
  color: white;
}

#Menu{
  display: flex;
  gap: 20px;
}

#Home{
  display: grid;
  width: 100%;
}

#UniversityLogos, .CertLogos{
  display: flex;
  justify-self: center;
  justify-content: space-evenly;
  gap: 20px;
  padding: 30px;
  align-items: center;
}

#UniversityLogos img{
  width: auto;
  height: 75px;
}

#HeroImages{
  border-bottom: 1px solid black;
  padding: 50px;
  display: flex;
  grid-auto-flow: column;
  justify-self: center;
  width: 70%;
  height: 150px;
}

#HeroImagesCell{
  display: grid;
  justify-items: center;
  width: 100%;
}

#HeroImagesCell span{
  font-weight: bolder;
  margin-top: 130px;
}

#HeroImages img{
  width: auto;
  height: 150px;
  mask-image: linear-gradient(0deg,#00000000 0%,white 50%);
  position: absolute;
  z-index: -1;
}

#About, #Sectoral, #Exams, #Certification, #References{
  display: grid;
}

#SectionContainer, #ButtonSectionContainer{
  display: grid;
  gap: 10px;
  width: 50%;
  justify-self: center;
  padding: 30px;
  text-align: justify;

}

#ButtonSectionContainer span{
  padding: 10px 30px;
}

#ButtonSectionContainer h3{
  border: 3px solid #85929E;
  background: linear-gradient(-180deg, #34495E,#1B2631);
  border-radius: 30px;
  padding: 10px 20px;
  align-items: center;
  color: white;
}

#ButtonSectionContainer h3:hover{
  cursor: pointer;
}

#ButtonSectionContainer span{
  display: none;
  border: 2px solid #85929E;
  border-radius: 0 0 20px 20px;
  border-top: none;
  margin-top: -10px;
  padding: 30px;
}

#Slider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 12.1vw;
}

#Slider img{
  width: 100%;
  position: absolute;
  z-index: -1;
}

#Slider button{
  height: 60px;
  width: 60px;
  justify-content: space-between;
  margin: 50px;
  padding: 20px;
  color: black;
  background: white;
  border: none;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 30px;
}

#Slider button:hover{
  cursor: pointer;
  background: black;
  color: white;
}

#Footer{
  margin-top: auto;
  width: calc(100% - 60px);
  top: 100%;
  display: flex;
  padding: 10px 30px;
  background: linear-gradient(-180deg, #34495E,#1B2631);
  color: white;
  justify-content: space-between;
  border-top: 1px solid gray;
  align-items: center;
}

#FooterRight{
  display: flex;
  gap: 10px;
}

#TopImage{
  width: 100vw;
}

#MenuButton, #MobileMenu{
  display: none;
}


@media only screen and (max-width: 1440px) {
  #Menu{
    font-size: 0.6em;
  }
  #HeroImagesCell span{
    font-weight: bolder;
    margin-top: 100px;
  }
  #HeroImages img{
    width: auto;
    height: 100px;
    mask-image: linear-gradient(0deg,#00000000 0%,white 50%);
    position: absolute;
    z-index: -1;
  }
  #UniversityLogos img{
    width: auto;
    height: 60px;
  }
}

@media only screen and (max-width: 480px){
  
  #UniversityLogos{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    gap: 20px;
    padding: 30px;
    align-items: center;
  }

  .CertLogos{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    gap: 20px;
    padding: 30px;
    align-items: center;
  }

  #Menu{
    display: none;
  }

  #HeroImages{
    display: none;
  }
  
  #UniversityLogos img{
    width: auto;
    height: 40px;
  }
  #Slider button{
    display: none;
  }

  #Header{
    display: grid;
    align-items: center;
    justify-content: center;
    width: calc(100% - 60px);
    padding: 10px 30px;
    border-bottom:1px solid gray;
    background: linear-gradient(-180deg, #34495E,#1B2631);
    color: white;
    justify-items: center;
  }

  #MenuButton{
    display: block;
    justify-self: center;
    padding: 10px;
  }

  #MenuButton button{
    padding: 10px 30px;
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    background: white;
    border: none;
    border-radius: 10px;
  }

  #MobileMenu{
    display: none;
    text-align: center;
    gap: 20px;
  }

  #MobileMenu a{
    border-bottom: 1px solid gray;
    padding: 10px;
  }
  #SectionContainer, #ButtonSectionContainer{
    display: grid;
    gap: 10px;
    width: 80%;
    justify-self: center;
    padding: 30px;
    text-align: justify;
  }
  h3{
    font-size: 0.7em;
  }
}